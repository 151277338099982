/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'skip-start-btn': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.71 5.093a.5.5 0 01.79.407v5a.5.5 0 01-.79.407L7 8.972V10.5a.5.5 0 01-1 0v-5a.5.5 0 011 0v1.528z"/><path pid="1" d="M0 4a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2H2a2 2 0 01-2-2zm15 0a1 1 0 00-1-1H2a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1z"/>',
    },
});
